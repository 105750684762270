export default (color) => `
	<path fill-rule="evenodd" clip-rule="evenodd" d="M13 11H11V31H12.4C10.1598 31 9.03969 31 8.18404 31.436C7.43139 31.8195 6.81947 32.4314 6.43597 33.184C6 34.0397 6 35.1598 6 37.4V38.6C6 40.8402 6 41.9603 6.43597 42.816C6.81947 43.5686 7.43139 44.1805 8.18404 44.564C9.03969 45 10.1598 45 12.4 45H18V55.6C18 57.8402 18 58.9603 18.436 59.816C18.8195 60.5686 19.4314 61.1805 20.184 61.564C21.0397 62 22.1598 62 24.4 62H47.6C49.8402 62 50.9603 62 51.816 61.564C52.5686 61.1805 53.1805 60.5686 53.564 59.816C54 58.9603 54 57.8402 54 55.6V20.4C54 18.1598 54 17.0397 53.564 16.184C53.1805 15.4314 52.5686 14.8195 51.816 14.436C50.9603 14 49.8402 14 47.6 14H24.4C22.1598 14 21.0397 14 20.184 14.436C19.4314 14.8195 18.8195 15.4314 18.436 16.184C18 17.0397 18 18.1598 18 20.4V31H13V11ZM126 34.4C126 32.1598 126 31.0397 126.436 30.184C126.819 29.4314 127.431 28.8195 128.184 28.436C129.04 28 130.16 28 132.4 28H155.6C157.84 28 158.96 28 159.816 28.436C160.569 28.8195 161.181 29.4314 161.564 30.184C162 31.0397 162 32.1598 162 34.4V45.6C162 47.8402 162 48.9603 161.564 49.816C161.181 50.5686 160.569 51.1805 159.816 51.564C158.96 52 157.84 52 155.6 52H132.4C130.16 52 129.04 52 128.184 51.564C127.431 51.1805 126.819 50.5686 126.436 49.816C126 48.9603 126 47.8402 126 45.6V34.4Z" fill="#0076DE"/>
	<mask id="sidesAntenna01Mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="6" y="11" width="156" height="51">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M13 11H11V31H12.4C10.1598 31 9.03969 31 8.18404 31.436C7.43139 31.8195 6.81947 32.4314 6.43597 33.184C6 34.0397 6 35.1598 6 37.4V38.6C6 40.8402 6 41.9603 6.43597 42.816C6.81947 43.5686 7.43139 44.1805 8.18404 44.564C9.03969 45 10.1598 45 12.4 45H18V55.6C18 57.8402 18 58.9603 18.436 59.816C18.8195 60.5686 19.4314 61.1805 20.184 61.564C21.0397 62 22.1598 62 24.4 62H47.6C49.8402 62 50.9603 62 51.816 61.564C52.5686 61.1805 53.1805 60.5686 53.564 59.816C54 58.9603 54 57.8402 54 55.6V20.4C54 18.1598 54 17.0397 53.564 16.184C53.1805 15.4314 52.5686 14.8195 51.816 14.436C50.9603 14 49.8402 14 47.6 14H24.4C22.1598 14 21.0397 14 20.184 14.436C19.4314 14.8195 18.8195 15.4314 18.436 16.184C18 17.0397 18 18.1598 18 20.4V31H13V11ZM126 34.4C126 32.1598 126 31.0397 126.436 30.184C126.819 29.4314 127.431 28.8195 128.184 28.436C129.04 28 130.16 28 132.4 28H155.6C157.84 28 158.96 28 159.816 28.436C160.569 28.8195 161.181 29.4314 161.564 30.184C162 31.0397 162 32.1598 162 34.4V45.6C162 47.8402 162 48.9603 161.564 49.816C161.181 50.5686 160.569 51.1805 159.816 51.564C158.96 52 157.84 52 155.6 52H132.4C130.16 52 129.04 52 128.184 51.564C127.431 51.1805 126.819 50.5686 126.436 49.816C126 48.9603 126 47.8402 126 45.6V34.4Z" fill="white"/>
	</mask>
	<g mask="url(#sidesAntenna01Mask0)">
		<rect width="180" height="76" fill="${color.hex}"/>
		<rect y="38" width="180" height="38" fill="black" fill-opacity="0.1"/>
	</g>
	<rect x="11" y="11" width="2" height="20" fill="white" fill-opacity="0.4"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12Z" fill="#FFEA8F"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M13 9C14.1046 9 15 8.10457 15 7C15 5.89543 14.1046 5 13 5C11.8954 5 11 5.89543 11 7C11 8.10457 11.8954 9 13 9Z" fill="white"/>
`