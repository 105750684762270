export default {
	50: '#E8EAF6',
	100: '#C5CAE9',
	200: '#9FA8DA',
	300: '#7986CB',
	400: '#5C6BC0',
	500: '#3F51B5',
	600: '#3949AB',
	700: '#303F9F',
	800: '#283593',
	900: '#1A237E'
}