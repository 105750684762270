export default {
	50: '#FBE9E7',
	100: '#FFCCBC',
	200: '#FFAB91',
	300: '#A1887F',
	400: '#FF7043',
	500: '#FF5722',
	600: '#F4511E',
	700: '#E64A19',
	800: '#D84315',
	900: '#BF360C'
}