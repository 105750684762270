export default {
	50: '#FFF3E0',
	100: '#FFE0B2',
	200: '#FFCC80',
	300: '#FF8A65',
	400: '#FFA726',
	500: '#FF9800',
	600: '#FB8C00',
	700: '#F57C00',
	800: '#EF6C00',
	900: '#E65100'
}