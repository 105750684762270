export default {
	50: '#FFFDE7',
	100: '#FFF9C4',
	200: '#FFF59D',
	300: '#FFF176',
	400: '#FFEE58',
	500: '#FFEB3B',
	600: '#FDD835',
	700: '#FBC02D',
	800: '#F9A825',
	900: '#F57F17'
}