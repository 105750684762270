export default {
	50: '#FFEBEE',
	100: '#FFCDD2',
	200: '#EF9A9A',
	300: '#E57373',
	400: '#EF5350',
	500: '#F44336',
	600: '#E53935',
	700: '#D32F2F',
	800: '#C62828',
	900: '#B71C1C'
}