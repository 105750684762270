export default {
	50: '#FFF8E1',
	100: '#FFECB3',
	200: '#FFE082',
	300: '#FFB74D',
	400: '#FFCA28',
	500: '#FFC107',
	600: '#FFB300',
	700: '#FFA000',
	800: '#FF8F00',
	900: '#FF6F00'
}