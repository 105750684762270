export default {
	50: '#F9FBE7',
	100: '#F0F4C3',
	200: '#E6EE9C',
	300: '#DCE775',
	400: '#D4E157',
	500: '#CDDC39',
	600: '#C0CA33',
	700: '#AFB42B',
	800: '#9E9D24',
	900: '#827717'
}